import React, { useEffect, useState } from "react";
import { fetchItems, getImageUrl } from "../firebase/fb";
import { useNavigate } from "react-router-dom";

//style
import "../stylesheets/item.css";

//resources
import defaultImage from "../images/Icono.jpg";

export function TopPublishers({ searchValue, location, topPlan }) {
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);
  const [itemsLoaded, setItemsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const itemsData = await fetchItems();
      setItems(itemsData);
      setItemsLoaded(true);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filterItems = async () => {
      if (!itemsLoaded) return;

      const filtered = items.filter(
        (item) =>
          item.town &&
          item.town
            .toLowerCase()
            .includes(location && location.toLowerCase()) &&
          item.plan &&
          item.plan.toLowerCase().includes(topPlan && topPlan.toLowerCase())
      );
      setFilteredItems(filtered);

      const urls = await Promise.all(filtered.map((item) => getImageUrl(item)));
      setImageURLs(urls);
      setItemsLoaded(true);
    };

    filterItems();
  }, [searchValue, location, items, itemsLoaded]);

  function openDetails(route) {
    navigate(route);
  }

  const getStatus = (item) => {
    var today = new Date();
    var actualTime = today.getHours() * 60 + today.getMinutes();
    var day = today.getDay();

    let dayOnWeek;
    switch (day) {
      case 1:
        dayOnWeek = "Lunes";
        break;
      case 2:
        dayOnWeek = "Martes";
        break;
      case 3:
        dayOnWeek = "Miercoles";
        break;
      case 4:
        dayOnWeek = "Jueves";
        break;
      case 5:
        dayOnWeek = "Viernes";
        break;
      case 6:
        dayOnWeek = "Sábado";
        break;
      case 7:
        dayOnWeek = "Domingo";
        break;
      default:
        dayOnWeek = "Undefined";
        break;
    }

    var schedule = item["schedule"];
    var status = "closed";

    for (var dayOfWeek in schedule) {
      const scheduleInfo = schedule[dayOfWeek];
      const scheduleDay = scheduleInfo["day"];
      if (scheduleDay === dayOnWeek) {
        const scheduleOpening = scheduleInfo["openingTime"];
        const timeInMillisOpening =
          scheduleOpening["seconds"] * 1000 +
          scheduleOpening["nanoseconds"] / 1000000;
        const dateOpening = new Date(timeInMillisOpening);
        const opening =
          parseInt(dateOpening.getHours()) * 60 +
          parseInt(dateOpening.getMinutes());

        const scheduleClosing = scheduleInfo["closingTime"];
        const timeInMillisClosing =
          scheduleClosing["seconds"] * 1000 +
          scheduleClosing["nanoseconds"] / 1000000;
        const dateClosing = new Date(timeInMillisClosing);
        const closing =
          parseInt(dateClosing.getHours()) * 60 +
          parseInt(dateClosing.getMinutes());

        if (actualTime > opening && actualTime < closing) {
          return "Abierto" + actualTime + ", " + opening + ", " + closing;
        } else if (actualTime < opening) {
          return "Abren pronto" + actualTime + ", " + opening + " ," + closing;
        } else if (actualTime > opening && actualTime > closing) {
          return "Ya cerró hoy" + actualTime + ", " + opening + ", " + closing;
        }

        status = "open";
      }
    }

    if (status === "closed") {
      return "Cerrado Hoy";
    }
  };

  if (filteredItems.length > 0) {
    return (
      <div className="contentItem">
        <h2 className="itemsTitle">Destacados</h2>
        {filteredItems.map((item, index) => (
          <div
            className="itemView"
            key={item.id}
            onClick={() =>
              openDetails(`/${item.town}/${item.category}/${item.route}`)
            }
          >
            {getStatus(item) === "Cerrado Hoy" && (
              <p className="statusLabel">Cerrado</p>
            )}
            {getStatus(item) === "Abren pronto" && (
              <p className="statusLabel">Abre Pronto</p>
            )}
            <img
              className="itemImage"
              src={imageURLs[index] || defaultImage}
              alt="Icono publicante"
            />
            <div className="itemInfDiv">
              <div className="itemInfo">
                <h3 className="itemTitle">
                  {item.name.length > 26
                    ? item.name.substring(0, 23) + "..."
                    : item.name}
                </h3>
                <p className="itemTown">{item.town}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
}
