import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthProvider from "../components/AuthProvider";

// resources
import icon from "../images/Icono.jpg";

// style
import "../stylesheets/userRegister.css";
import { registerNewUser } from "../firebase/fb";

const Checkbox = ({ value, selected, onChange }) => {
  const handleClick = () => {
    onChange(value);
  };

  return (
    <label>
      <input type="checkbox" value={value} checked={selected} onChange={handleClick} />
      {value}
    </label>
  );
};


const removeAccents = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

const UserRegister = () => {
  const [state, setState] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  function handleUserLoggedIn(user) {
    navigate("/");
  }

  function handleUserNotRegistered(user) {
    setState(2);
    setCurrentUser(user);
  }

  function handleUserUnlogged() {
    navigate("/login");
  }

  const handleOptionChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((selected) => selected !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredOptions = [
    "Amagá", "Andes", "Angelópolis", "Betania",
    "Betulia", "Caramanta", "Ciudad Bolívar",
    "Concordia", "Fredonia", "Hispania",
    "Jardín", "Jericó", "La Pintada",
    "Montebello", "Pueblorrico", "Salgar",
    "Santa Bárbara", "Támesis", "Tarso",
    "Titiribí", "Urrao", "Valparaíso", "Venecia"
  ].filter((option) => removeAccents(option).toLowerCase().includes(removeAccents(searchTerm).toLowerCase()));

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  async function addUser (e) {
    e.preventDefault()
  
    // Form data capture
    const municipalities = selectedOptions;
    const id = currentUser.uid
    const codes = []
    const address = []
    const picture = ""
    const userData ={name, phone, municipalities, id, codes, address, picture}
    
    await registerNewUser(userData);
    navigate("/")
  }
  
  
  if (state === 2) {
    return (
      <div className="userRegisterView">
        {/* Header */}
        <div className="supDivUserRegister">
          <img className="iconLogin" src={icon} alt="Icono Suroeste a la mano" />
          <strong className="salmTitle">Te damos la bienvenida a Suroeste a la mano</strong>
        </div>

        {/* Basic form information */}
        <div className="formDivUserRegister">
          <form>
            <label id="nameLabel">¿Cómo quieres que te llamemos?</label>
            <input id="nameText" type="text" placeholder="Tu nombre" value={name}
              onChange={handleNameChange} />
            <label id="phoneLabel">¿Cuál es tu número celular?</label>
            <input id="phoneText" type="number" placeholder="Tu número celular" value={phone}
              onChange={handlePhoneChange}/>
            <label id="municipalitiesLabel">¿cuáles son tus municipios de interes?</label>
            <input id='municipalitiesText'type="text" placeholder="Buscar municipio..." 
            value={searchTerm} onChange={handleSearchTermChange}/>

            <div className="checkboxGroup">
              {filteredOptions.map((option) => (
                <Checkbox 
                  key={option}
                  value={option}
                  selected={selectedOptions.includes(option)}
                  onChange={handleOptionChange}
                />
              ))}
            </div>

            <button onClick={addUser}>Registrarse</button>
            
          </form>
        </div>
      </div>
    );
  }

  return (
    <AuthProvider
      onUserLoggedIn={handleUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserUnlogged={handleUserUnlogged}
    >
      <div>loading ...</div>
    </AuthProvider>
  );
};

export default UserRegister;
