import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../stylesheets/carousel.css";

export default function CarouselImages({ data, navigateToLink }) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleCloseButtonClick = () => {
    setSelectedImageIndex(null);
  };

  if (navigateToLink) {
    return (
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
        stopOnHover={false}
        showThumbs={false}
      >
        {data.map((item, index) => (
          <div key={index} className="carousel">
            <a href={item.link || ""}>
              <img src={item.imageUrl} alt={` ${index + 1}`} />
            </a>
          </div>
        ))}
      </Carousel>
    );
  } else {
    return (
      <div>
        <div className="thumbnailContainer">
          {data.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index}`}
              className={`thumbnailImage ${
                selectedImageIndex === index ? "selected" : ""
              }`}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
        {selectedImageIndex !== null && (
          <div className="fullscreenCarouselContainer">
            <div className="closeButton" onClick={handleCloseButtonClick}>
              X
            </div>
            <div className="fullscreenCarousel">
              <Carousel selectedItem={selectedImageIndex} showArrows={true}>
                {data.map((image, index) => (
                  <div key={index} className="carouselImageContainer">
                    <img
                      src={image}
                      alt={`Fullscreen Image ${index}`}
                      className="fullscreenImage"
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        )}
      </div>
    );
  }
}
