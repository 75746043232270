import { useParams, useNavigate } from "react-router-dom";
import React, { useState } from "react";

//resources
import icon from "../images/Icono.jpg"
import back from "../images/back.png"

//style 
import '../stylesheets/header.css'

export default function ComponentHeader(){
    const { town, category } = useParams();
    const navigate = useNavigate();
    
    return(
        <div className="supDivMain">
            <img className="backButton" src={back} alt="back" onClick={()=>navigate('/'+town+'?category='+category)}></img>
            <img className="iconMain" src={icon} alt="Suroeste a la mano" onClick={()=>navigate('/')}></img>
            <strong className="salmTitleMain" onClick={()=>navigate('/')}>Suroeste a la Mano</strong>
            <p className="superiorRoute" onClick={()=>navigate('/'+town)}>/ {town}</p>
            <p className="superiorRoute" onClick={()=>navigate('/'+town+'?category='+category)}>/{category}</p>      
        </div>
    )
}