import React from "react";

//Components
import TownSelection from "./TownSelection";
import { GeneralSearch } from "./GeneralSearch";

// Style
import "../../stylesheets/appBar.css";

// Resources
import icon from "../../images/Icono.jpg";

export function AppBar({ onSearch, onLocationChange }) {
  const handleLocationChange = (value) => {
    onLocationChange(value);
  };

  return (
    <div className="appBar">
      <div className="appBrand">
        <img className="appIcon" src={icon} alt="Icono Suroeste a la mano" />
        <h1 className="appName">Suroeste a la Mano</h1>
      </div>
      <TownSelection onLocationChange={handleLocationChange} />
      <GeneralSearch onSearch={onSearch} />
    </div>
  );
}
