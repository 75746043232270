import React from "react";

//resources
import icon from "../images/Icono.jpg"
import facebookIcon from "../images/facebook.png"
import playStoreIcon from "../images/playStore.jpg"
import instagram from "../images/Instagram.png"
import whatsapp from "../images/whatsapp.png"

//style 
import '../stylesheets/footer.css'

export default function Footer(){
    return(
        <div className="infDivMain">
            <img className="iconFooter" src={icon} alt="Icono Suroeste a la mano"></img>
            <strong className="salmTitleFooter">Suroeste a la Mano</strong>            
            
            <div>
                <a href="https://play.google.com/store/apps/details?id=com.conexionweb.suroestealamano" target="_blank" className="buttonsFooter">
                    <img src={playStoreIcon} alt="app" className="socialIcon" />
                    <p className="salmTitleFooter">Nuestra App</p>
                </a>
            </div>

            <div>
                <a href="https://wa.me/573233118416" target="_blank" className="buttonsFooter">
                    <img src={whatsapp} alt="app" className="socialIcon" />
                    <p className="salmTitleFooter">Contáctanos</p>
                </a>
            </div>

            <div>
                <a href="https://instagram.com/suroestealamano_oficial?utm_source=qr&igshid=NGExMmI2YTkyZg%3D%3D" target="_blank" className="buttonsFooter">
                    <img src={instagram} alt="social" className="socialIcon" />
                    <p className="salmTitleFooter">Instagram</p> 
                </a>
            </div>

            <div>
                <a href="https://www.facebook.com/profile.php?id=100063577177237" target="_blank" className="buttonsFooter">
                    <img src={facebookIcon} alt="social" className="socialIcon" />
                    <p className="salmTitleFooter">Facebook</p> 
                </a>
                
            </div>
        </div>
    )
}