import React, { useState } from "react"

//import components
import { auth, userExist } from "../firebase/fb"
import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from "firebase/auth"

import AuthProvider from "../components/AuthProvider";
import Header from "../components/Header";

//import utils
import { useNavigate } from "react-router-dom";

//resources
import icon from "../images/Icono.jpg"
import photo from "../images/banner.jpg"

//style 
import '../stylesheets/login.css'


export default function Login() {

    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);

    async function handleOnClick() {
        const googleProvider = new GoogleAuthProvider();
        await signInWithGoogle(googleProvider);
        async function signInWithGoogle(googleProvider) {
            try {
                await signInWithPopup(auth, googleProvider);
            } catch (error) {
            }
        }
    }

    function handleUserLoggedIn(user) { navigate('/') }
    function handleUserNotRegistered(user) { navigate('/user-register') }
    function handleUserUnlogged() { setCurrentState(1) }


    return (
        <div className="loginView">
            <Header />
            <div className="loginMainView">

            </div>
        </div>
    )


    // if (state === 1){
    //     return(
    //         <div className="loginView">

    //             <div className="supDivLogin">
    //                 <img className="iconLogin" src={icon} alt="Icono Suroeste a la mano"></img>
    //                 <strong className="salmTitle">Suroeste a la mano</strong>
    //             </div>



    //             {/* <div className="mainView">
    //                 <img className="photoLogin" src={photo} alt="Icono Suroeste a la mano"></img>
    //                 <div className="authMethodsView">
    //                     <strong>Regístrate o ingresa para continuar</strong>

    //                     <form className="loginForm">
    //                         <input id="emailText"
    //                             type="text" 
    //                             placeholder="Correo electrónico"/>

    //                         <input id="passwordText"
    //                             type="password" 
    //                             placeholder="Contraseña"/>

    //                         <button className='loginButton'>Iniciar sesión</button>

    //                     </form>

    //                     <button className="googleLoginButton" onClick={handleOnClick}>Login with Google</button>
    //                 </div>
    //             </div> */}

    //         </div>
    //     )
    // }

    // return (
    //     <AuthProvider
    //         onUserLoggedIn={handleUserLoggedIn}
    //         onUserNotRegistered={handleUserNotRegistered}
    //         onUserUnlogged={handleUserUnlogged}><div>loading ...</div>
    //     </AuthProvider>);
}