import React, { useState, useEffect } from "react";
import "../stylesheets/categories.css";
import { useParams, useLocation } from "react-router-dom";
import {
  getUniqueSubcategories,
  getUniqueSubcategoriesForCategory,
} from "../firebase/fb";

export function Subcategories({ setSubcategory }) {
  const { town } = useParams();
  const [activeSubcategories, setActiveSubcategories] = useState([]);

  const params = useLocation();
  const queryParams = new URLSearchParams(params.search);
  const category = queryParams.get("category");

  useEffect(() => {
    async function getSubcategories() {
      if (town != null) {
        const subcategories = await getUniqueSubcategories(town, category);
        setActiveSubcategories(subcategories);
      } else {
        const subcategories = await getUniqueSubcategoriesForCategory(category);
        setActiveSubcategories(subcategories);
      }
    }

    getSubcategories();
  }, [category]);

  function subcategorySelected(item) {
    setSubcategory(item);
  }

  if (activeSubcategories.length > 2) {
    if (category != null && category != "Todo") {
      return (
        <div className="categoriesContainer">
          {activeSubcategories.map((item) => (
            <div className="categoryView" key={item}>
              <button
                className="subcategoriesButton"
                onClick={() => subcategorySelected(item)}
              >
                {item}
              </button>
            </div>
          ))}
        </div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}
