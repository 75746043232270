import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  setDoc,
} from "firebase/firestore/lite";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export async function userExist(uid) {
  const docRef = doc(db, "users", uid);
  const res = await getDoc(docRef);
  return res.exists();
}

export async function registerNewUser(userData) {
  try {
    const collectionRef = collection(db, "users");
    const docRef = doc(collectionRef, userData.id);
    await setDoc(docRef, {
      name: userData.name,
      phone: userData.phone,
      municipalities: userData.municipalities,
      codes: userData.codes,
      address: userData.address,
      picture: userData.picture,
    });
  } catch (error) {
    console.log(error);
  }
}

export async function updateUser(user) {
  try {
    const collectionRef = collection(db, "users");
    const docRef = doc(collectionRef, user.uid);
    await setDoc(docRef, user);
  } catch (error) {}
}

export async function fetchItems() {
  const itemsCollection = collection(db, "publishers");
  const itemsSnapshot = await getDocs(itemsCollection);
  const itemsData = itemsSnapshot.docs.map((doc) => {
    const itemData = doc.data();
    itemData.id = doc.id;
    return itemData;
  });
  return itemsData;
}

export async function fetchPublisherData(route) {
  const itemsCollection = collection(db, "publishers");
  const q = query(itemsCollection, where("route", "==", route));
  const itemsSnapshot = await getDocs(q);
  const itemsData = itemsSnapshot.docs.map((doc) => {
    const itemData = doc.data();
    itemData.id = doc.id;
    return itemData;
  });
  return itemsData;
}

export const getURLByPattern = async (pattern, id) => {
  try {
    const storageRef = ref(storage, "publishers/" + id + "/images/");
    const files = await listAll(storageRef);

    // Filtrar el archivo que coincida con el patrón
    const imageFile = files.items.find((file) => file.name.startsWith(pattern));

    if (imageFile) {
      const url = await getDownloadURL(imageFile);
      return url;
    } else {
      console.log("No se encontró ninguna imagen que coincida con el patrón.");
      return null;
    }
  } catch (error) {
    console.log("Error al obtener la URL de descarga:", error);
    return null;
  }
};

export const getPublisherImages = async (id, cover) => {
  try {
    const storageRef = ref(storage, "publishers/" + id + "/images/");
    const files = await listAll(storageRef);

    const filteredFiles = files.items.filter(
      (itemRef) => itemRef.name !== cover
    );

    const downloadUrls = await Promise.all(
      filteredFiles.map((itemRef) => getDownloadURL(itemRef))
    );

    const imagesUrl = downloadUrls;
    return imagesUrl;
  } catch (error) {
    console.error("Error al obtener las imágenes:", error);
    return null;
  }
};

export const getBannerImages = async (item) => {
  try {
    const storageRef = ref(storage, "banners/" + item.image);
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    console.error("Error al obtener las imágenes:", error);
    return null;
  }
};

export const getBannerInfo = async (town) => {
  try {
    const bannersCollection = collection(db, "banners");
    const bannersQuery = query(
      bannersCollection,
      where("region", "==", town),
      where("active", "==", true)
    );
    const snapshot = await getDocs(bannersQuery);
    let banners = [];
    for (const doc of snapshot.docs) {
      let bannerData = doc.data();
      try {
        const imageUrl = await getBannerImages(bannerData);
        bannerData.imageUrl = imageUrl;
        banners.push(bannerData);
      } catch (error) {
        console.error("Error al obtener la imagen para el banner:", error);
      }
    }
    return banners;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getImageUrl = async (item) => {
  const storageRef = ref(
    storage,
    "publishers/" + item.id + "/images/" + item.image
  );
  const url = await getDownloadURL(storageRef);
  return url;
};

export async function getUniqueCategoriesForTown(town) {
  try {
    const collectionRef = collection(db, "publishers");
    const q = query(collectionRef, where("town", "==", town));
    const itemsSnapshot = await getDocs(q);
    const uniqueCategories = new Set();

    uniqueCategories.add("Todo");
    itemsSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.category) {
        uniqueCategories.add(data.category);
      }
    });

    const uniqueCategoriesArray = Array.from(uniqueCategories);
    uniqueCategoriesArray.sort();

    const todoIndex = uniqueCategoriesArray.indexOf("Todo");
    if (todoIndex !== -1) {
      uniqueCategoriesArray.splice(todoIndex, 1);
      uniqueCategoriesArray.unshift("Todo");
    }

    return uniqueCategoriesArray;
  } catch (error) {
    console.error("Error al obtener las categorías:", error);
    return [];
  }
}

export async function getUniqueCategoriesForAll() {
  try {
    const collectionRef = collection(db, "publishers");
    const itemsSnapshot = await getDocs(collectionRef);
    const uniqueCategories = new Set();

    uniqueCategories.add("Todo");
    itemsSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.category) {
        uniqueCategories.add(data.category);
      }
    });

    const uniqueCategoriesArray = Array.from(uniqueCategories);
    uniqueCategoriesArray.sort();

    const todoIndex = uniqueCategoriesArray.indexOf("Todo");
    if (todoIndex !== -1) {
      uniqueCategoriesArray.splice(todoIndex, 1);
      uniqueCategoriesArray.unshift("Todo");
    }

    return uniqueCategoriesArray;
  } catch (error) {
    console.error("Error al obtener las categorías:", error);
    return [];
  }
}

export async function getUniqueSubcategories(town, category) {
  try {
    const collectionRef = collection(db, "publishers");
    const q = query(
      collectionRef,
      where("town", "==", town),
      where("category", "==", category)
    );
    const itemsSnapshot = await getDocs(q);
    const uniqueSubcategories = new Set();

    itemsSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.subcategory) {
        uniqueSubcategories.add(data.subcategory);
      }
    });

    uniqueSubcategories.add("Todo");

    const uniqueSubcategoriesArray = Array.from(uniqueSubcategories);
    uniqueSubcategoriesArray.sort();

    const todoIndex = uniqueSubcategoriesArray.indexOf("Todo");
    if (todoIndex !== -1) {
      uniqueSubcategoriesArray.splice(todoIndex, 1);
      uniqueSubcategoriesArray.unshift("Todo");
    }

    return uniqueSubcategoriesArray;
  } catch (error) {
    console.error("Error al obtener las categorías:", error);
    return [];
  }
}

export async function getUniqueSubcategoriesForCategory(category) {
  try {
    const collectionRef = collection(db, "publishers");
    const q = query(collectionRef, where("category", "==", category));
    const itemsSnapshot = await getDocs(q);
    const uniqueSubcategories = new Set();

    uniqueSubcategories.add("Todo");
    itemsSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.subcategory) {
        uniqueSubcategories.add(data.subcategory);
      }
    });

    const uniqueSubcategoriesArray = Array.from(uniqueSubcategories);
    uniqueSubcategoriesArray.sort();

    const todoIndex = uniqueSubcategoriesArray.indexOf("Todo");
    if (todoIndex !== -1) {
      uniqueSubcategoriesArray.splice(todoIndex, 1);
      uniqueSubcategoriesArray.unshift("Todo");
    }
    return uniqueSubcategoriesArray;
  } catch (error) {
    console.error("Error al obtener las categorías:", error);
    return [];
  }
}

export async function getActiveTowns() {
  try {
    const collectionRef = collection(db, "regions");
    const q = query(collectionRef, where("active", "==", true));
    const itemsSnapshot = await getDocs(q);
    const uniqueTowns = new Set();

    uniqueTowns.add("Todos los municipios");
    itemsSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.name) {
        uniqueTowns.add(data.name);
      }
    });

    const uniqueTownsArray = Array.from(uniqueTowns);
    uniqueTownsArray.sort();

    const todoIndex = uniqueTownsArray.indexOf("Todos los municipios");
    if (todoIndex !== -1) {
      uniqueTownsArray.splice(todoIndex, 1);
      uniqueTownsArray.unshift("Todos los municipios");
    }
    return uniqueTownsArray;
  } catch (error) {
    console.error("Error al obtener los municipios:", error);
    return [];
  }
}
