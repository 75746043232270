import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

//Functionalities
import {
  fetchPublisherData,
  getImageUrl,
  getPublisherImages,
} from "../firebase/fb";
//Components
import { CoverImage } from "../components/CoverImage";
import ComponentHeader from "../components/ComponentHeader";
import CarouselImages from "../components/CarouselImages";
import Footer from "../components/Footers";

//StyleSheet
import "../stylesheets/publisherDetails.css";

//Resources
import star from "../images/star.png";

export function PublisherDetails() {
  const { route } = useParams();
  const [itemData, setItemData] = useState({});
  const [itemId, setItemId] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [carouselImages, setCarouselImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  //Get publisher info
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchPublisherData(route);
      if (data.length > 0) {
        const firstItem = data[0];
        setItemData(firstItem);
        setItemId(firstItem.id);
      }
    };

    fetchData();
  }, [route]);

  //Get main image
  useEffect(() => {
    if (itemData.image) {
      fetchImageURLs(itemData);
    }
  }, [itemData]);

  const fetchImageURLs = async (item) => {
    const url = await getImageUrl(item);
    setImageUrl(url);
  };

  //Get carousel images
  useEffect(() => {
    if (itemId && carouselImages.length === 0) {
      fetchImages(itemId, itemData);
    }
  }, [itemId, carouselImages, itemData]);

  const fetchImages = async (itemId, itemData) => {
    const imagesUrl = await getPublisherImages(itemId, itemData.image);
    setCarouselImages(imagesUrl);
  };

  return (
    <>
      <Helmet>
        <title>{`${itemData.name}`} | Suroeste a la Mano</title>
        <meta
          name="description"
          content={`Encuentra en Suroeste A La Mano Toda la información comercial de ${itemData.name} y de otros establecimientos de ${itemData.category} y ${itemData.subcategory} en el municipio de ${itemData.town} ubicado en la subregión del Suroeste Antioqueño`}
        />
        <meta
          name="keywords"
          content={`Suroeste a la mano, suroeste a la mano, ${itemData.tags}, ${itemData.name}, ${itemData.town}, ${itemData.category}, ${itemData.subcategory}`}
        />
      </Helmet>
      <div className="publisherDetailsMainView">
        <ComponentHeader className="headerDetails" />
        <div className="detailsView">
          <div className="leftDetailsView">
            <CoverImage className="coverImage" imageUrl={imageUrl} />
            <h1 className="publisherName">{itemData.name}</h1>
            {/* <div className="publisherCalification">
            <p className="calificationPublisherText">Calificanos</p>
            <img className="calificationPublisherImage" src={star} alt="Calificación" />
            <p className="calificationPublisherText">{itemData.calification}</p>
          </div> */}
          </div>

          <div className="rightDetailsView">
            {itemData.cards?.map((card, index) => (
              <div key={index} className="cardsDivDetails">
                <h4 className="cardTitleDetails">{card.title}</h4>
                <p className="cardTextDetails">{card.text}</p>
              </div>
            ))}

            <h2 className="publisherAddress">{itemData.address}</h2>

            <div className="buttonsView">
              {itemData.buttons?.map((button, index) => (
                <div key={index} className="verticaldivRegister">
                  <a href={button.action} target="_blank" rel="noreferrer">
                    <div className="prevButton">
                      <img
                        src={button.icon}
                        alt={button.title}
                        className="prevIcon"
                      />
                    </div>
                  </a>
                </div>
              ))}
            </div>

            <div className="carouselMainDiv">
              <div className="carouselDiv">
                <h2>Galería</h2>
                <CarouselImages data={carouselImages} navigateToLink={false} />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
