//__________________________Imports______________________________
//Hooks and utilities
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

//import components
import { AppBar } from "../components/AppBar/AppBar";
import Footer from "../components/Footers";
import { Banner } from "../components/Banner";
import { Categories } from "../components/Categories";
import { Item } from "../components/Item";
import { TopPublishers } from "../components/TopPublishers";
import { Subcategories } from "../components/Subcategories";

//style
import "../stylesheets/main.css";

//__________________________Component___________________________
export default function Main() {
  //States and variables
  const [searchValue, setSearchValue] = useState("");
  const [location, setLocation] = useState("");
  const [categories, setCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState("Todo");
  const [subcategorySelected, setSubcategorySelected] = useState("Todo");
  const townRef = useRef("");
  const [helmetData, setHelmetData] = useState("");

  //Get params from url query
  const params = useLocation();
  const queryParams = new URLSearchParams(params.search);
  const category = queryParams.get("category");
  var { town } = useParams();

  useEffect(() => {
    if (town === undefined) {
      townRef.current = "";
    } else {
      townRef.current = town;
    }
    setLocation(townRef.current);
  }, [town]);

  useEffect(() => {
    setKeywords();
  }, [town, categories]);

  useEffect(() => {
    if (category != null) {
      setCategorySelected(category);
    }
  }, [category]);

  //Methods
  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleLocationChange = (value) => {
    setLocation(value);
  };

  const handleCategories = (value) => {
    setCategories(value);
  };

  const handleCategorySelected = (value) => {
    setCategorySelected(value);
  };

  const handleSubcategorySelected = (value) => {
    setSubcategorySelected(value);
  };

  const setKeywords = () => {
    var keywords = "";

    for (var i = 0; i < categories.length; i++) {
      keywords += categories[i] + " en " + location + ", ";
    }
    setHelmetData(keywords);
  };

  //Render
  return (
    <>
      <Helmet>
        <title>
          {`${
            category && location
              ? `${category} en ${location}`
              : category
              ? `${category} en el Suroeste Antioqueño`
              : location
              ? "Suroeste a la Mano | " + location
              : "Suroeste a la Mano | Home"
          }`}
        </title>
        <meta
          name="description"
          content={`Descubre todo lo que el Suroeste Antioqueño tiene para ofrecer en un solo lugar. Encuentra ${helmetData}`}
        />
        <meta name="keywords" content={`${helmetData}`} />
      </Helmet>
      <div className="homeView">
        <AppBar
          onSearch={handleSearch}
          onLocationChange={handleLocationChange}
        />

        <div className="mainHomeView">
          <Banner town={location} />

          <div className={`categoriesView`}>
            <Categories
              activeCategories={categories}
              setCategory={handleCategorySelected}
            />
          </div>

          <div className="categoriesView">
            <Subcategories setSubcategory={handleSubcategorySelected} />
          </div>

          {searchValue === "" && categorySelected === "Todo" ? (
            <div className="itemsView">
              <TopPublishers
                searchValue={""}
                location={location}
                topPlan={"Destacado principal"}
              />
            </div>
          ) : null}

          <div className="itemsView">
            <Item
              searchValue={searchValue}
              location={location}
              onCategoriesChange={handleCategories}
              category={categorySelected}
              subcategory={subcategorySelected}
            />
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
}
