import React from "react";

//resources
import icon from "../images/Icono.jpg"

//style 
import '../stylesheets/header.css'

export default function Header(){
    return(
        <div className="supDivMain">
            <img className="iconMain" src={icon} alt="Icono Suroeste a la mano"></img>
            <strong className="salmTitleMain">Dashboard Suroeste a la mano</strong>
            
        </div>
    )
}

