import React, { useEffect} from "react"
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged} from "firebase/auth"
import { auth, userExist } from "../firebase/fb"


export default function AuthProvider ({children, onUserLoggedIn, onUserUnlogged, onUserNotRegistered}){
    
    const navigate = useNavigate();
    useEffect(()=>{
        onAuthStateChanged(auth, async(user)=> {
            if(user){
                const isRegistered = await userExist(user.uid);
                if(isRegistered){
                    onUserLoggedIn(user);
                    navigate('/');
                }else{
                    onUserNotRegistered(user);
                    navigate('/user-register');
                }
            }else{
                onUserUnlogged()
            }

        });
    },[onUserLoggedIn, onUserNotRegistered, onUserUnlogged])
    return (
        <div>
            {children}
        </div>
    )
}