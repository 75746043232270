import React, { useState } from "react";

//Libraries
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";

// Style
import "../../stylesheets/appBar.css";

export function GeneralSearch({ onSearch }) {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    onSearch(value);
  };

  return (
    <div className="searchDiv">
      <Icon path={mdiMagnify} size={1} />
      <input
        className="searchInput"
        id="searchText"
        type="text"
        placeholder="Buscar"
        value={searchValue}
        onChange={handleSearch}
      />
    </div>
  );
}
