import React, { useEffect, useState } from "react";
import { fetchItems, getImageUrl } from "../firebase/fb";
import { useNavigate, useLocation } from "react-router-dom";

//style
import "../stylesheets/item.css";

//resources
import defaultImage from "../images/Icono.jpg";
import star from "../images/star.png";

export function Item({
  searchValue,
  location,
  onCategoriesChange,
  subcategory,
}) {
  const navigate = useNavigate();
  const params = useLocation();
  const queryParams = new URLSearchParams(params.search);
  var category = queryParams.get("category");

  if (category === "Todo" || !category) {
    category = "";
  }
  if (subcategory === "Todo") {
    subcategory = "";
  }

  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [itemsLoaded, setItemsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const itemsData = await fetchItems();
      if (itemsData.length > 0) {
        setItems(itemsData);
        setItemsLoaded(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filterItems = async () => {
      if (!itemsLoaded) return;

      const filtered = items.filter(
        (item) =>
          ((item.name &&
            item.name
              .toLowerCase()
              .includes(searchValue && searchValue.toLowerCase())) ||
            (item.tags &&
              item.tags
                .toLowerCase()
                .includes(searchValue && searchValue.toLowerCase())) ||
            (item.category &&
              item.category
                .toLowerCase()
                .includes(searchValue && searchValue.toLowerCase())) ||
            (item.subcategory &&
              item.subcategory
                .toLowerCase()
                .includes(searchValue && searchValue.toLowerCase()))) &&
          item.town &&
          item.town
            .toLowerCase()
            .includes(location && location.toLowerCase()) &&
          item.category &&
          item.category
            .toLowerCase()
            .includes(category && category.toLowerCase()) &&
          item.subcategory &&
          item.subcategory
            .toLowerCase()
            .includes(subcategory && subcategory.toLowerCase())
      );
      setFilteredItems(filtered);

      // Fetch image URLs for filtered items
      const urls = await Promise.all(filtered.map((item) => getImageUrl(item)));
      setImageURLs(urls);
      setItemsLoaded(true);
    };

    filterItems();
  }, [searchValue, location, items, category, itemsLoaded, subcategory]);

  useEffect(() => {
    const categoriesSet = new Set();
    const itemsTownFiltered = items.filter(
      (item) =>
        item.town &&
        item.town.toLowerCase().includes(location && location.toLowerCase())
    );
    itemsTownFiltered.forEach((item) => {
      categoriesSet.add("Todo");
      if (item.category) {
        categoriesSet.add(item.category);
      }
    });
    const categoriesArray = Array.from(categoriesSet);
    setCategories(categoriesArray);
    if (categories.length > 0) {
      onCategoriesChange(categoriesArray);
    }
  }, [filteredItems]);

  function openDetails(route) {
    navigate(route);
  }

  const getStatus = (item) => {
    var today = new Date();
    var actualTime = today.getHours() * 60 + today.getMinutes();
    var day = today.getDay();

    let dayOnWeek;
    switch (day) {
      case 1:
        dayOnWeek = "Lunes";
        break;
      case 2:
        dayOnWeek = "Martes";
        break;
      case 3:
        dayOnWeek = "Miercoles";
        break;
      case 4:
        dayOnWeek = "Jueves";
        break;
      case 5:
        dayOnWeek = "Viernes";
        break;
      case 6:
        dayOnWeek = "Sábado";
        break;
      case 7:
        dayOnWeek = "Domingo";
        break;
      default:
        dayOnWeek = "Undefined";
        break;
    }

    var schedule = item["schedule"];
    var status = "closed";

    if (schedule.length != 0) {
      for (var dayOfWeek in schedule) {
        const scheduleInfo = schedule[dayOfWeek];
        const scheduleDay = scheduleInfo["day"];
        if (scheduleDay === dayOnWeek) {
          const scheduleOpening = scheduleInfo["openingTime"];
          const timeInMillisOpening =
            scheduleOpening["seconds"] * 1000 +
            scheduleOpening["nanoseconds"] / 1000000;
          const dateOpening = new Date(timeInMillisOpening);
          const opening =
            parseInt(dateOpening.getHours()) * 60 +
            parseInt(dateOpening.getMinutes());

          const scheduleClosing = scheduleInfo["closingTime"];
          const timeInMillisClosing =
            scheduleClosing["seconds"] * 1000 +
            scheduleClosing["nanoseconds"] / 1000000;
          const dateClosing = new Date(timeInMillisClosing);
          const closing =
            parseInt(dateClosing.getHours()) * 60 +
            parseInt(dateClosing.getMinutes());

          if (actualTime > opening && actualTime < closing) {
            return "Abierto" + actualTime + ", " + opening + ", " + closing;
          } else if (actualTime < opening) {
            return (
              "Abren pronto" + actualTime + ", " + opening + " ," + closing
            );
          } else if (actualTime > opening && actualTime > closing) {
            return (
              "Ya cerró hoy" + actualTime + ", " + opening + ", " + closing
            );
          }

          status = "open";
        }
      }

      if (status === "closed") {
        return "Cerrado Hoy";
      }
    }
  };

  if (filteredItems.length > 0) {
    return (
      <div className="contentItem">
        {filteredItems.map((item, index) => (
          <div
            className="itemView"
            key={item.id}
            onClick={() =>
              openDetails(`/${item.town}/${item.category}/${item.route}`)
            }
          >
            {item.image && (
              <img
                className="itemImage"
                src={imageURLs[index] || defaultImage}
                alt="Icono publicante"
              />
            )}
            <div className="itemInfDiv">
              <div className="itemInfo">
                <h2 className="itemTitle">
                  {item.name.length > 21
                    ? item.name.substring(0, 18) + "..."
                    : item.name}
                </h2>

                <p className="itemTown">{item.town}</p>
              </div>
              {/* <div className="itemCalification">
                <img className="calificationImage" src={star} alt="Icono publicante" />
                <p className="calificationText">{item.calification}</p>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
}
