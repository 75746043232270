//Hooks and utilities
import React, { useEffect, useState, useRef } from "react";
import { getBannerInfo } from "../firebase/fb";

//components
import CarouselImages from "./CarouselImages";

//Resources
import icon from "../images/Icono.jpg";

//style
import "../stylesheets/banner.css";

export function Banner({ town }) {
  const [banners, setBanners] = useState([]);
  const [links, setLinks] = useState([]);
  const townRef = useRef(town);

  const bannerText = [
    "Restaurantes",
    "Hoteles",
    "Turismo",
    "Tu región",
    "Servicios",
    "Almacenes",
    "Tiendas",
    "Suroeste",
    "Farmacias",
  ];

  const [currentText, setCurrentText] = useState(bannerText[0]);
  const [textIndex, setTextIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (isTyping) {
        if (currentText.length < bannerText[textIndex].length) {
          setCurrentText(
            bannerText[textIndex].slice(0, currentText.length + 1)
          );
        } else {
          setIsTyping(false);
          setTimeout(() => {
            setIsTyping(true);
            setTextIndex((prevIndex) => (prevIndex + 1) % bannerText.length);
            setCurrentText("");
          }, 4000);
        }
      }
    }, 180);

    return () => clearInterval(typingInterval);
  }, [textIndex, currentText, isTyping]);

  //Get carousel images
  useEffect(() => {
    townRef.current = town;

    const fetchImages = async () => {
      if (townRef.current === "") {
        townRef.current = "Todos";
      }

      try {
        const activeBanners = await getBannerInfo(townRef.current);
        setBanners(activeBanners);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchImages();
  }, [town]);

  if (banners && banners.length > 0) {
    return (
      <div className="mainBannerView">
        <div className="verticalBannerDiv">
          <img className="iconBannerView" src={icon} alt="icon" />
          <div className="horizontalBannerDiv">
            <h1 className="animatedBannerText">{currentText}</h1>
            <h1 className="staticText">a la Mano</h1>
          </div>
        </div>
        <div className="bannerView">
          <CarouselImages data={banners} navigateToLink={true} />
        </div>
      </div>
    );
  } else {
    return null;
  }
}
