import React, { useState, useEffect } from "react";
import "../stylesheets/categories.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  getUniqueCategoriesForTown,
  getUniqueCategoriesForAll,
} from "../firebase/fb";

export function Categories({ setCategory }) {
  const { town } = useParams();
  const navigate = useNavigate();
  const [activeCategories, setActiveCategories] = useState([]);
  const location = useLocation();

  useEffect(() => {
    async function getCategories() {
      if (town != null) {
        const categories = await getUniqueCategoriesForTown(town);
        setActiveCategories(categories);
      } else {
        const categories = await getUniqueCategoriesForAll(town);
        setActiveCategories(categories);
      }

      let searchParams = new URLSearchParams(location.search);
      let categoryFromURL = searchParams.get("category");

      if (categoryFromURL) {
        setCategory(categoryFromURL);
      } else {
        setCategory("Todo");
      }
    }

    getCategories();
  }, [town, location.search, setCategory]);

  const categorySelected = (item) => {
    setCategory(item);
    if (town != null && item !== "Todo") {
      navigate(`/${town}?category=${item}`);
    } else if (town != null && item === "Todo") {
      navigate(`/${town}`);
    } else if (item !== "Todo") {
      navigate(`?category=${item}`);
    } else {
      navigate("/");
    }
  };

  if (activeCategories.length > 2) {
    return (
      <div className="categoriesContainer">
        {activeCategories.map((item) => (
          <div className="categoryView" key={item}>
            <button
              className="categoriesButton"
              onClick={() => categorySelected(item)}
            >
              {item}
            </button>
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
}
