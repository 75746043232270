import React, { useState, useEffect } from "react";

// Style
import "../../stylesheets/appBar.css";

//Libraries and utils
import { useNavigate } from "react-router-dom";
import { getActiveTowns } from "../../firebase/fb";
import { useParams } from "react-router-dom";

export default function TownSelection({ onLocationChange }) {
  //States and variables
  const [location, setLocation] = useState();
  const [activeTowns, setActiveTowns] = useState([]);
  const navigate = useNavigate();

  //Methods
  const handleLocationChange = (event) => {
    var value = event.target.value;
    if (value === "Todos los municipios") {
      value = "";
    }
    setLocation(value);
    onLocationChange(value);
    const route = "/" + value;
    navigate(route);
  };

  const { town } = useParams();

  useEffect(() => {
    if (town != null) {
      setLocation(town);
    }

    if (!activeTowns.length > 0) {
      getTowns();
    }
  }, [activeTowns.length, town]);

  async function getTowns() {
    const towns = await getActiveTowns();
    setActiveTowns(towns);
  }

  //Render
  return (
    <div className="townSelector">
      <p className="lineText">Buscar en: </p>
      <select
        id="locSelection"
        className="selector"
        onChange={handleLocationChange}
        value={location}
      >
        {activeTowns.map((town) => (
          <option key={town} value={town}>
            {town}
          </option>
        ))}
      </select>
    </div>
  );
}
